import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

// Fine tune this on project needs
const INTERNAL_REGEX = new RegExp(`^/(?!/)`);

const GlobalLink = ({ to, children, ...props }) => {
    if (INTERNAL_REGEX.test(to)) {
        return (
            <GatsbyLink to={to} {...props}>
                <>
                    {children}
                </>
            </GatsbyLink>
        );
    }
    // Remove activeClassName prop from external links
    const { activeClassName, ...other } = props;
    return (
        <a href={to} {...other}>
            <>
                {children}
            </>
        </a>
    );
}

export const Link = GlobalLink;