import React from "react"
import PropTypes from "prop-types"
import extendWith from "lodash/extendWith"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"


const formatUrl = src => src && src.startsWith('//') ? `https:${src}` : src;

const normalize = image => {
  if (!image) return image;
  if (image.gatsbyImageData) {
    const { images } = image.gatsbyImageData;
    const src = images.fallback.src;
    const params = typeof URLSearchParams === 'function' ? new URLSearchParams(src.split('?')[1]) : null;
    return {
      src: formatUrl(src),
      width: params ? params.get('w') : null,
      height: params ? params.get('h'): null
    }
  }
}

function SEO({ title: defTitle, description: defDescription, image, type, seo = {}, lang, meta, keywords }) {
  const data = useStaticQuery(graphql`
    fragment AssetInfo on ContentfulAsset {
      file {
        details {
          image {
            width
            height
          }
        }
      }
    } 
    fragment SeoFragment on ContentfulSeo {
      title
      description
      canonical
      ogdescription
      ogtitle
      robots
      twittersite
      ogimage {
        ...AssetInfo
        gatsbyImageData(width: 1200, layout: FIXED, quality: 60, placeholder: TRACED_SVG)
      }
    } 
    query DefaultSEOQuery {
      site {
        siteMetadata {
          title
          name
          description
          author
          siteUrl
        }
      }
      home: contentfulPaginaHomepage(contentful_id: {eq: "6cmMCQv79cobCZaQzvs9B4"}){
        seo {
          ...SeoFragment
        }
      }
    }`
  );
  // console.log('image: ', image);
  const resultSeo = extendWith({},
    // Default seo della home
    data.home.seo,
    // Campi seo passati direttamente al componente
    {
      title: defTitle,
      ogtitle: defTitle,
      description: defDescription,
      ogdescription: defDescription,
      ogimage: image,
    },
    // Componente seo
    seo,
    // Non sovrascrivere con null o undefined
    (a, b) => b === undefined || b === null ? a : undefined
  );
  const { title, description, ogtitle, ogdescription, ogimage, robots, twittersite } = resultSeo;
  const metaDescription = description || data.site.siteMetadata.description;
  // console.log('ogimage: ', ogimage);
  const img = normalize(ogimage);
  // console.log('img: ', img);
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.description}`}
      defaultTitle={data.site.siteMetadata.title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogtitle || title,
        },
        {
          property: `og:description`,
          content: ogdescription || metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:site_name`,
          content: data.site.siteMetadata.name,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:site`,
          content: twittersite || data.site.siteMetadata.name,
        },
        {
          name: `twitter:title`,
          content: ogtitle || title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        }
      ]
        .concat(robots ? {
          name: 'robots',
          content: robots
        } : [])
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : []
        )
        .concat(
          img ? [{
            property: `og:image`,
            content: img.src,
          }, {
            property: `twitter:image`,
            content: img.src,
          }]
        : [])
        .concat(img && img.width && img.height ? [{
          property: 'og:image:width',
          content: img.width
        }, {
          property: 'og:image:height',
          content: img.height
        }, {
          property: 'twitter:image:width',
          content: img.width
        }, {
          property: 'twitter:image:height',
          content: img.height
        }
        ] : [])
        .concat(meta)}
      link={seo && seo.canonical ? [{
        rel: 'canonical',
        href: seo.canonical
      }] : []}
    />);
}

SEO.defaultProps = {
  lang: `it`,
  meta: [],
  type: `website`,
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.shape({
    fluid: PropTypes.object,
    fixed: PropTypes.object
  }),
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
}

export default SEO;