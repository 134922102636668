import React from 'react'
import { Link } from './link'


const Breadcrumb = ({ list }) => {
  return (
    <div className="breadcrumb" role="navigation" aria-label="breadcrumb">
      <ol className="breadcrumb__list" itemType="http://schema.org/BreadcrumbList" itemScope="">
        {list.map((item, i) => (
          <li className="breadcrumb__item" itemType="http://schema.org/ListItem" itemScope="" itemProp="itemListElement" key={item.link || item.title}>
            <div aria-level={i+1}>
              {i < list.length - 1 ? (
                <Link title={item.title} className="breadcrumb__link" 
                  itemid={`https://www.ariadnelearning.it${item.link}`}
                  itemtype="https://schema.org/WebPage"
                  itemprop="item"
                  to={item.link}>
                  <span itemProp="name">{item.title}</span>
                </Link>
              ) : (
                <span itemProp="name" className="breadcrumb__link breadcrumb__link--active">{item.title}</span>
              )}
            </div>
            <meta content={i+1} itemProp="position" />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumb;