import React, { useState, useCallback, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';
import ariadne from '../images/ariadne-eLearning-logo.png'
import { Link } from '../components/link';
import slugify from '../utility/slugify';


const MenuItem = ({ item, pagineSnodo, menuMap, toggleMenuVoice }) => {
  const { name, link, children } = item;
  const contentfulItem = item.contentful_id ? pagineSnodo.find(page => page.contentful_id === item.contentful_id) : {};

  const pageName = contentfulItem.titolo || name;
  const pageLink = (contentfulItem.titolo && `/${slugify( contentfulItem.titolo)}/`) || link;
  const pageChildren = contentfulItem.pagine || children;
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
    <li role="menuitem" itemProp="name" className={`navigation__item ${!!pageChildren ? "has-child" : ""}
      ${menuMap[name] ? ' navigation__item--open' : ''}`}>
      <Link to={pageLink} title={pageName} className="navigation__link" activeClassName="navigation__link--active" partiallyActive={true}
        itemProp="url">
        {pageName}
      </Link>
      {!!pageChildren && pageChildren.filter(item => item.header || item.contentful_id).length && (
        <span className="navigation__angle d-none d-xl-inline"></span>
      )}
      {!!pageChildren && pageChildren.filter(item => item.header || item.contentful_id).length &&
        <>
          <button onClick={(e) => toggleMenuVoice(e, item.name || item.titolo)} aria-label="toggle"
            aria-expanded={!!menuMap[item.name || item.titolo]}
            className={`d-xl-none navigation__toggle ${menuMap[item.name || item.titolo] ? ' open' : ''}`}>
            <span className="navigation__angle navigation__angle--right"></span>
          </button>
          <ul className="navigation__sublist">
            {pageChildren.filter(item => item.header || item.contentful_id).map((child) =>
              <li key={child.name || child.titolo}>
                <Link to={child.link || `${pageLink}${slugify(child.titolo)}/`} title={child.name || child.titoloLista || child.titolo} 
                  itemProp="url" activeClassName="active">
                  {child.name || child.titolo}
                </Link>
              </li>
            )}
          </ul>
        </>
      }
    </li>
  );
};

const Header = ({ status }) => {

  const [open, setOpen] = useState(false);
  const [menuMap, setMenuMap] = useState({});

  const toggleMenu = useCallback(() => setOpen(value => !value), []);
  const toggleMenuVoice = useCallback((e, name) => {
    e.preventDefault();
    setMenuMap(map => ({ ...map, [name]: !map[name] }));
  }, []);

  useEffect(() => {
    if (open && (status === 'hidden' || status === 'hiding')) {
      setOpen(false);
    }
  }, [open, status]);

  const headerClass = classnames('header', { 
    'header--fixed': status === 'scrolled', 
    'header--hidden': status === 'hidden', 
    'header--hiding': status === 'hiding', 
    'header--menu-open': open
  });



  const { navigationJson, allContentfulPaginaSnodo } = useStaticQuery(graphql`{
    navigationJson {
      header {
        name
        link
        header
        contentful_id
      }
    }
    allContentfulPaginaSnodo(filter: { node_locale: { eq: "it"}}) {
      edges {
        node {
          contentful_id
          titolo
          pagine {
            contentful_id
            titolo
          }
        }
      }
    }
  }`);
  const pagineSnodo = allContentfulPaginaSnodo.edges.map(edge => edge.node);
  const navigation = navigationJson.header;

  return (
    <header className={headerClass} 
      role="banner">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-3 d-flex justify-content-between align-items-center">
            <div className="site-logo">
              <Link to="/" title="Ariadne e-learning" className="site-logo__link">
                <img src={ariadne} alt="Ariadne e-learning"  className="site-logo__img d-print-none" />
                <span className="site-logo__name d-none d-print-block">Ariadne e-learning</span>
              </Link>
            </div>
            <button className={`hamburger d-xl-none ${open ? 'open' : ''}`} aria-label="menu" onClick={toggleMenu} >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
          <div className="col-12 col-xl-9 d-print-none px-0 px-xl-3">
            <nav className={`header__navigation navigation px-3 px-xl-0 ${open ? 'header__navigation--open' : ''}`}
              itemType="http://schema.org/SiteNavigationElement" itemScope="itemscope" role="navigation">
              <ul className="navigation__list d-flex flex-column flex-xl-row" role="menu" aria-orientation="horizontal">
                {navigation.filter(item => item.header).map((item) => (
                  <MenuItem item={item} pagineSnodo={pagineSnodo} menuMap={menuMap} toggleMenuVoice={toggleMenuVoice} key={item.name} />
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
