import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import Header from './header'
import Footer from './footer'
import Hero from './hero'
import '../css/custom.scss'

const HIDE_THRESHOLD = 300;
const SCROLLED_THRESHOLD = 600;

const Layout = ({ children, hero }) => {  
  const [headerStatus, setHeaderStatus] = useState('default');
  useEffect(() => {
    const scrollListener = throttle(() => {
      if (document.body.scrollTop > SCROLLED_THRESHOLD || document.documentElement.scrollTop > SCROLLED_THRESHOLD) {
        setHeaderStatus('scrolled');
      } else if (document.body.scrollTop > HIDE_THRESHOLD || document.documentElement.scrollTop > HIDE_THRESHOLD) {
        setHeaderStatus(current => current === 'default' || current === 'hidden' ? 'hidden' : 'hiding');
      } else {
        setHeaderStatus('default');
      }
    }, 100);
    document.addEventListener('scroll', scrollListener);
    scrollListener();
    return () => document.removeEventListener('scroll', scrollListener);
  }, []);


  return (
    <div className="layout-wrapper">
      <Header status={headerStatus} />
      
      <main className="main-wrapper">
        {hero ? (<Hero {...hero} />) : null}
        {children}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;