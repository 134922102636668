import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, withArtDirection, getImage } from 'gatsby-plugin-image'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from "../components/link"
import Breadcrumb from './breadcrumb'


const Hero = ({ title, subtitle, heroDesktop, heroMobile, breadcrumb, scrollDown, className, category, links, 
  fullTitle }) => {
 
  const images = withArtDirection(getImage(heroDesktop), [
    {
      media: "(max-width: 800px)",
      image: getImage(heroMobile),
    }
  ]);

  return (
    <div className={`hero${className ? ` ${className}` : ''}`}>
      <GatsbyImage className="hero__bg-img d-none d-lg-block" image={images} alt="hero" />
      <div className="container">
        {breadcrumb && (
          <div className="row">
            <div className="col-12 mt-5 pt-4 d-flex">
              <Breadcrumb list={breadcrumb} />
              {category && <div className="hero__category d-none d-lg-inline-block">{category}</div>}
            </div>
          </div>
        )}
        <div className="row">
          <div className={`col-12${fullTitle ? '' : ' col-lx-6 col-lg-7'}`}>
            <>
              <h1 className="hero__title">{title}</h1>
              {subtitle && <h2 className="hero__subtitle">{subtitle}</h2>}
            </>
          </div>
        </div>
        {scrollDown && (
          <div className="row">
            <div className="col-12 text-center">
              <ScrollLink href={`#${scrollDown}`} to={scrollDown} smooth={true} className="hero__scroll-down"
                 aria-label="Casi di successo">
              </ScrollLink>
            </div>
          </div>
        )}
        {links && (
          <div className="row mt-3 mt-md-5 pt-4 pt-lg-5">
            {links.map(link => (
              <div className="col-6 col-lg-4 mb-5 mb-lg-0 text-center" key={link.path} >
                <Link to={link.path} className="hero__link d-inline-flex flex-column align-items-center">
                  <span className="hero__link-icon">
                    {link.icon}
                  </span>
                  <span className="mt-4">{link.name}</span>
                </Link>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="dots dots--left-bottom"></div>
      <div className="dots dots--right-top d-none d-xl-block"></div>
    </div>
  );
}

export default Hero;

export const heroFragment = graphql`
  fragment HeroFragmentDesktop on File {
    childImageSharp {
      gatsbyImageData(width: 1920, layout: CONSTRAINED, quality: 80, placeholder: TRACED_SVG)
    }
  }
  fragment HeroFragmentMobile on File {
    childImageSharp {
      gatsbyImageData(width: 576, layout: CONSTRAINED, quality: 80, placeholder: TRACED_SVG)
    }
  }
  fragment HeroContentfulDesktop on ContentfulAsset {
    gatsbyImageData(layout: FULL_WIDTH, quality: 80, placeholder: TRACED_SVG)
  }
  fragment HeroContentfulMobile on ContentfulAsset {
    gatsbyImageData(width: 576, layout: FULL_WIDTH, quality: 80, placeholder: TRACED_SVG)
  }
`;