const slug = require('slug');
slug.defaults.modes['pretty'] = {
  replacement: '-',
  symbols: true,
  remove: /[.]/g,
  lower: true,
  charmap: slug.charmap,
  multicharmap: slug.multicharmap
};
slug.defaults.mode = 'pretty';
// const replaceSpacesWithDash = string => string ? string.replace(new RegExp(' ', 'g'), '-') : string;

const slugify = (text, option) => {
  if (!!text) {
    return slug(text, option);
  }
};

module.exports = slugify;